.switch {
	&--disabled {
		.switch__toggler {
			cursor: not-allowed;
		}
	}
}

.text-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.button--text:hover {
	color: #777e91;
}
.select__current-arrow {
	margin-left: auto;
}
