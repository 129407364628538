@import 'ui-kit/admin';
@import 'icons-font';
@import 'font-icon-style';

$grey2: #777E90;


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.count-label {
  position: relative;
  z-index: 8;
  gap: 10px;
  &--user-management {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &--transaction {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    flex-wrap: wrap;
  }

  &--left {
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
  }
  &--end, &--filter-type {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    flex-wrap: wrap; 
    @media screen  and (max-width: 991px){     
      justify-content: flex-start;
    }
    @media screen  and (max-width: 620px){
      .input-with-select {
        width: 100%;
      }
    }
  }
  &--filter-start {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap; 
    @media screen  and (max-width: 620px){
      .input, .input-with-select {
        width: 100%;
      }
    }
  }
  &--filter-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap; 
    @media screen  and (max-width: 991px){      
      justify-content: flex-start;
    }
    @media screen  and (max-width: 620px){
      .input, .input-with-select {
        width: 100%;
      }
    }
  }
  &--gap-10 {
    gap: 10px;
  }
  &--mt-24 {
    margin-top: 24px;
  }
  &__text {
    color: var(--grey-color);
    font-size: 14px;
    span {
      font-weight: 500;
      color: var(--font-color);
    }
  }
  @media screen  and (max-width: 767px){
    flex-wrap: wrap;
  }
  .input-with-select {
    .select {
      background: $main-color;
      &__current {
        color: #fff !important;
      }
      .select__current, .select__current--unselect {
        .select__current-arrow svg path {
          stroke: #fff;
        }
      }
    }
  }
}

.filter-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap; 
}

.arrow-icon {
  width: 6px;
  margin-left: 10px;
  transform: translateY(-2px);
}

.btn-icon {
  display: flex;
  align-items: center;
  width: 16px;
  &--max-width {
    width: auto;
    max-width: 16px;
  }
}

.td-value {
  font-weight: 500;
  &--grey {
    color: $text-grey;
  }
}

.td {
  .switch {
    width: unset;
  }
  &--right {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      .td-action {
        justify-content: flex-end;
      }
      .switch {
        width: unset;
      }
  }
  &--center{
    text-align: center;
    .switch {
      justify-content: center;
      margin: 0 auto;
    }
  }
  &--word-br {
    p{
      max-width: 200px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
  }
  &--copy-value {
    display: flex;
    justify-content: flex-end;
  }

  &--documents {
    position: relative;
    display: flex;
    justify-content: center;
    .documents-icon {
      display: inline-block;
      width: 16px;
    }
  }

  &--reason {
    position: relative;
    p {
      max-width: 355px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .reason-subtitle {
      position: absolute;
      top: 70px;
      left: 78px;
      z-index: 10;
      max-width: 320px;
      width: 100%;
      padding: 12px 16px;
      color: #fff;
      background: #009A5A;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 10px;
       visibility: hidden;
       opacity: 0;
       transition: all 0.3s ease;

      &::before {
        content: '';
        position: absolute;
        top: -14px;
        left: 30px;
        width: 0;
        height: 0;
        z-index: 3;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 25px solid #009A5A;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
      }  
    }

    .reason-text{
      &:hover+.reason-subtitle {
          visibility: visible;
          opacity: 1;
          transition: all 0.3s ease;

          &::before {
            visibility: visible;
            opacity: 1;
            transition: all 0.3s ease;
          }
        }
      }
    }

  &--date {
    text-align: right;
  }
}


.table--2fa {
  .tr {
    grid-template-columns: 0.5fr 1fr 100px 100px 85px 2fr 85px;
  }
}

.user-settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 28px;
  &__item {
    position: relative;
    left: 0 !important;
    padding: 15px 12px;
    border-radius: 8px 8px 0px 0px;
    &:first-child:after {
      content: '';
      position: absolute;
      width: 100%;height: 7px;
      bottom: -6px;
      left: 0.5px;
      background: #fff;
      z-index: 3;
      display: none;
      @media screen  and (max-width: 1200px){
        display: none !important;
      }
    }
    &:last-child:after {
      content: '';
      position: absolute;
      width: 100%;height: 7px;
      bottom: -6px;
      right: 0.5px;
      background: #fff;
      z-index: 3;
      display: none;
      @media screen  and (max-width: 1200px){
        display: none !important;
      }
    }
    
    &.active {
      background-color: #fff;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
      0px 2px 4px rgba(96, 97, 112, 0.16);
      .user-settings__link {
        font-weight: 500;
      }
      &:first-child:after, &:last-child:after  {
        display: block;
      }
    }
    @media screen  and (max-width: 1200px){       
      padding: 10px 20px;
      font-size: 14px;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 18px;
    color: #011E26;
  }

  &--fs, &--start {
    justify-content: flex-start;
  }
}

.content-block {
  position: relative;
  z-index: 2;
  &__inside {
    &--ip {
      padding: 30px 16px;
    }
    &--login-his {
      padding: 16px 16px;
    }
    &--deposit-his  {
      padding: 24px 39px;
    }
    &--type2 {
      padding: 16px;
    }
    &--type3 {
      padding: 20px 16px 60px 16px;
    }
     
    &--type4 {
      padding: 30px;
      @media screen and (max-width: 991px) {

        padding: 16px;
      }
    }
    &--padding-20 {
      padding: 20px;
    }
    &--dispute {
      padding: 16px 16px 170px 16px;
    }
    &--panel-only {
      padding: 16px;
    }
  }
  &--user-management {
    margin-top: 0;
    border: none;
  }
  &--admin-panel {
    background: rgba(255, 255, 255, 0.4);
  }
  &--cashdesk {
    background: #fff;
    padding: 16px;
    .content-block__inside {
      padding: 0;
    }
  }
  &--big-mt {
    margin-top: 60px;
  }
  &--transaction {
    margin-top: 0;

    .table-block {
      margin-top: 0;
    }
  }
}

.table-block {
  position: relative;
  z-index: 2;
  &--ip {
    margin-top: 0;
  }
  &--mt10 {
    margin-top: 10px;
  }
  &--mt-24 {
    margin-top: 24px;
  }
  &--mt-0 {
    margin-top: 0;
  }
  &--border-none {
    border: none;
  }
  &--big-mt {
    margin-top: 32px;
  }
}

.personal-info {

  &__name {
    &--financial {
      margin: 0 30px 0 0;
      font-size: 18px;
      line-height: 21px;
    }
  }
  &__text {
    &--unverified {
      text-transform: uppercase;
      color: #F44336;
      font-weight: 500;
    }
  }
  &--action {
    margin-left: auto;
    .personal-info__desc {
      justify-content: flex-end;
    }
    .personal-info__btn {
      max-width: 18px;
      flex: 0 0 18px;
    }
  }
  &--type2 {
    display: flex;
    align-items: center;    
    gap: 8px 24px;
    padding: 0 16px;
    &:not(:last-child) {
      border-bottom: 1px solid #E6E8EC;
      padding-bottom: 16px;
    }
    &:last-child {
      padding-bottom: 16px;
    }
    &+ .personal-info {
     margin-top: 16px;
    }
    .personal-info__name {
      min-width: 40%;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #777E91;
    }
  }
}

.user-info {
  &__block {
    &--financial:not(:last-child) {
      margin-right: 97px;
    }
    &--financial {
      display: flex;
      align-items: center;
    }
  }

  &--ip {
    width: 100%;
  }
  &--type2 {
    flex-direction: column;
    margin-top: 16px;
  }
}

.account-panel {
  display: flex;
  align-items:center;
  justify-content: space-between;
  @media screen and (max-width: 991px) {
    padding-bottom: 16px;
    border-bottom: 1px solid #E6E8EC;
  }
  &--type2 {
   
    padding-bottom: 16px;
    border-bottom: 1px solid #E6E8EC;
    .account-header {
      padding-bottom: 0;
      border: none;
    }
  }

}

.account-header {
  display: flex;
  align-items:center;
  gap: 8px;
  width: 564px;
  max-width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #E6E8EC;
  &__icon {
    display: flex;
    width: 20px;
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.user-account-form {
  width: 564px;
  max-width: 100%; 
}

.block-subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}

.account-vip {
  display: flex;
  align-items: center;
  height: 42px;
  gap: 4px;
  background: #FCFCFD;
  border: 1px solid #E6E8EC;
  border-radius: 8px;  
  padding: 2px 8px;
  &__icon {
    display: flex;
    width: 24px;
  }
  .switch {
    margin-left: 12px;
  }

}

.financial-system-info {
  .financial-system-tr {
    grid-template-columns: 1fr 0.5fr;
  }
  &--fee {
    max-width: 250px;
    width: 100%; 
  }

  &--spot {
    max-width: 330px;
    width: 100%;
  }
}

.financial-system-tr {
  display: grid;
  align-items: center;
  
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.financial-system-td {
  font-size: 14px;
  line-height: 18px;
  color: var(--font-color);
  &__bold {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  &--right {
    text-align: right;
  }
}

.select {
  &--financial {
    width: 174px;
    height: 42px;

    .select__current {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: var(--font-color);
    }
  }
  &--multiple-options {
    height: auto;
    min-height: 50px;
  }
}

.select-multiple-options{
  cursor: pointer;
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.select-option-label {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 31px;
  padding: 0 8px;
  background: #E6E8EC;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  &__delete{
    width: 10px;
    min-width: 10px;
    padding: 1px;
  }
}

.select-extra-text {
  color: var(--grey-color);
}

.documents-icon:hover {
  svg{
    stroke: var(--ui-main-color);
  }
  &+.documents-file {
    visibility: visible;
    opacity: 1;
  }
}

.documents-file {
  position: absolute;
  bottom: -5px;
  left: -53px;
  z-index: 10;
  background: transparent;
  width: 115px;
  height: 152px;
  visibility: hidden;
  opacity: 0;

  &__element {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 104px;
    height: 119px;
    padding: 15px 13px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 1px solid #E6E8EC;
  }
  &__preview {
    width: 44px;
    height: 55px;
    margin-bottom: 16px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  &__descr {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
}

.popup {
  &--width-560 {
    width: 560px;
  }
}

.popup-header {
  &__title {
    &--ta-left {
      text-align: left;
    }
  }
  &--max-width-300 {
    max-width: 300px;
    margin: 0 auto;
  }
}

.popup-header-coin {
  display: flex;
  width: 70px;
  margin: 0 auto 16px auto;
}

.popup-text {
  &--max-width-250 {
    max-width: 250px;
    margin: 0 auto;
  }
  &--mt-8 {
    margin-top: 8px;
  }
  &--mt-12 {
    margin-top: 12px;
  }
}

.popup-submit {
  display: flex;
  column-gap: 16px;
  .button {       
    font-size: 16px;    
  }
  &--column {
    display: flex;
    flex-direction: column;
    align-items: center;
    .button:not(:last-child){
      margin-bottom: 5px;
    }
  }
  &__group {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &--type3 {
    justify-content: space-between;
  }
  &--big-mt {
    margin-top: 50px;
  }
}

.deposit-history {

  &--filter {
    display: flex;
    justify-content: space-between;
  }

  &__list {
    display: flex;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 40px;
    }
    &.active {
      .deposit-history__link {
        color: #011E26;
        font-weight: 500;
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__item:last-child {
    .deposit-history__link::before {
      left: 50%;
      transform: translateX(-50%);
    } 
  }

  &__link {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    color: #777E91;

    &::before {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      width: calc(100% + 24px);
      background-color: $green;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.tabs-list {
  display: flex;
  &__link {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    color: #777E91;
    &::before {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      width: calc(100% + 24px);
      background-color: $green;
      opacity: 0;
      visibility: hidden;
    }
  }
  &__item {
    padding: 0 24px;
    &:first-child {
      //padding-left: 0;
      &.active {
        //padding-left: 24px;
      }
    }
    @media screen and (max-width: 1199px){
      padding: 0 24px;
      &:first-child {
        padding-left: 0;
        &.active {
          padding-left: 24px;
        }
      }
    }
    &.active {
      .tabs-list__link {
        color: #011E26;
        font-weight: 500;
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__item:last-child {
    .tabs-list__link::before {
      left: 50%;
      transform: translateX(-50%);
    } 
  }
  &--type2 {
    .tabs-list__link {
      color: #011E26;
    }
  }  
}

.table-panel {
  display: flex;  
  align-items: center;
  justify-content: space-between;
  gap: 0 40px;
  gap: 10px;
  &__radio-group {
    margin-right: 50px;
    @media screen and (max-width: 1200px){
      margin-right: 0;
    }
    .radio {
      margin-bottom: 0;
      &+.radio {
        margin-top: 5px;
      }
    }
  }
}

.table-panel-tab {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 40px;
  margin-right: 20px;
  margin-left: 10px;
  @media screen and (max-width: 1200px){
    gap: 5px 20px;
  }
  &__item {      
    &.active {
      .table-panel-tab__link {
        color: #011E26;
        font-weight: 500;
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__link {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    color: #777E91;

    &::before {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      width: calc(100% + 10px);
      background-color: $green;
      opacity: 0;
      visibility: hidden;
    }
  }
  
}

.item-disable {
  display: none;
}

.table-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  &--fee-fiat {
    .input {
      width: 120px;
    }
    .select__drop {
      min-width: 100%;
      width: max-content;
    }
  }
}

.table-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  input {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    display: inline-block;
    border: 1px solid #E6E8EC;
    border-radius: 4px;
    height: 32px;
    text-align: right;
    width: 85px;
    padding: 4px 12px;
    outline: none;
    color: var(--font-color);
    &::placeholder {
      font-size: 14px;
      color: inherit;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &--disable {
    display: none;
  }
  &--center {
    justify-content: center;
  }
  &--start {
    justify-content: flex-start;
    input {
      text-align: left;
    }
  }
  &--full-width {
    input {
      width: 100%;
    }
  }
  &--width-100 {
    input {
      width: 100px;
    }
  }
  &--editable {
    flex: 1;
    input {
      border: none;
      max-width: 100%;
      width: 100%;
      padding: 0;
      background: transparent;
      color: $text-grey;
    }
  }
}

.editable-value {
  display: flex;
  align-items: center;
  gap: 4px;
}

.title-block {
  &--admin-managment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &--between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &--allocation {
    
    flex-direction: column;
    align-items: flex-start;
  }
  &--trades {
    justify-content: space-between;
  }
  &--big-mt {
    margin-top: 48px;
  }
}

.search-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px 16px;
  margin-top: 8px;
  .input {
    margin: 0;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: flex-start;
    .input {
      width: 100%;
    }
  }
}

.block-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
}

.top-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 15px;
}

.admin-managment-btn {
  position: relative;  
  display: inline-flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px; 
  background-color: #fff;
  border-radius: 3px;
  font-size: 14px;
  line-height: 1.2;
  cursor: pointer;
  color: var(--font-color);
  .plus-icon {
    &--action {
     
    }
  }
}

.admin-managment-popup {
  .button {
    font-size: 16px;
  }  
}

.table-footer {
  .button {
    width: 198px;
    border-radius: 40px;
    &--transparent {
      border: 1px solid #777E91;
      background: transparent;
      color: var(--font-color);
      margin-right: 15px;
    }
  }
  &--inside {
    border-top: 1px solid #e8e8e8;
    padding: 16px;
    .button {
      width: auto;
    }
  }
}


// .forms-wrapper {
//   display: flex;
//   gap: 16px;
//   flex-wrap: wrap;
// }

.form {
  &--settings {
    display: flex;
    flex-direction: column;
    padding: 24px 30px;
    width: 100%;

    .form__content {
      margin-top: 0;
      max-width: 280px;
    }


  }
}

.form-submit {
  &--settings {
    margin-top: auto;
    max-width: 280px;
    .button {
      background: var(--ui-main-color);
      &:hover {
        background: var(--ui-button-bg-hover);
      }
    }
  }
}

.title-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}


.user-settings+.forms-block {
  padding: 0;

  .form {
    margin-top: 0;
  }
}

.user-settings {
  &--settings {
    justify-content: flex-start;
  }
  &--flex-start {
    justify-content: flex-start;
  }
  &--trades {
    justify-content: flex-start;
    padding-left: 1px;
    margin-bottom: -1px;
    .user-settings__item {
      margin-right: 66px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        box-shadow:  none;
        .user-settings__link {
          font-weight: 400;
        }
      }
    }
    .user-settings__link {
      color: #777E91;
      font-weight: 400;
    }
  }
  &+.content-block {
    margin-left: -0.5px;
   
    @media screen and (max-width: 1200px) {
      margin-left: 0;
      margin-top: 16px;
    }
    @media screen and (max-width:991px) {
      margin-left: -12px;     
    }
  }
  &+.table-block {
    margin-left: -0.5px;
    @media screen and (max-width: 1200px) {
     
      margin-top: 16px;
    }
  }
  
}

.tfa-block {
  &__info {
    max-width: 564px;
    width: 100%;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #011E26;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E6E8EC;
  }

  &__text {
    font-size: 14px;
    line-height: 150%;
    color: #777E91;
    margin-bottom: 16px;
  }

  .form-submit--settings {
    max-width: 100%;
  }

  &__item {
    margin-bottom: 24px;
  }
  &__content {
    margin-top: 24px;
  }

  &__item-title {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #011E26;
    padding-left: 48px;
    margin-bottom: 24px;

    span {
      position: absolute;
      top: -4px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: $green;
      color: #fff;
    }
  }

  &__item-text {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 150%;
    color: #777E91;
  }

  &__item-apps {
    display: flex;
  }
  

  &__item-appstore {
    width: 136px;
    margin-right: 24px;
  }

  &__item-google {
    width: 136px;
  }
  &__qr {
    display: flex;
    flex-wrap: wrap;
  }

  &__qr-info {
    max-width: 390px;
    width: 100%;
  }

  &__qr-img {
    width: 150px;
    margin-right: 24px;
  }

  &__qr-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #777E91;
  }

  &__qr-code {
    margin-bottom: 8px;
    padding: 15px 16px;
    border-radius: 12px;
    border: 1px solid #E6E8EC;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #03BC70;
  }

  &__qr-error {
    position: relative;
    padding: 6px 6px 6px 30px;
    background: rgba(244, 67, 54, 0.1);
    border-radius: 12px;
    font-size: 14px;
    line-height: 150%;
    color: #F44336;

    &::before {
      content: "";
      position: absolute;
      top: 7px;
      left: 6px;
      display: block;
      width: 18px;
      height: 18px;
      background-image: url('../images/info-circle.svg');
      background-repeat: no-repeat;
    }
  }

  &__input {
    .tfa-block-input {
      display: block;
      height: 48px;
      max-width: 564px;
      width: 100%;
      padding: 15px;
      border-radius: 12px;
      border: 1px solid #E6E8EC;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      outline: none;

      &::placeholder {
        color: #777E91;
        opacity: 0.5;
      }
    }
  }

  &__input-descr {
    font-size: 14px;
    line-height: 150%;
    color: #011E26;
  }
  .switch {
    margin-bottom: 8px;
  }
}


.input {
  &--validation {
    .input-item {
      border-color: #F44336;
    }
    
    .input-icon {
      right: 46px;
    }
    .input-icon:nth-child(2) {
      right: 18px;
    }
    .icon-cancel-icon {
      font-size: 20px;
    }
    .icon-eye-icon:before {
      color: #F44336;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-bottom: 8px;   
    .input__name {
      margin-bottom: 0;
    }
    .radio__text {
      font-weight: 500;
    }
    &--with-switcher {
      margin-bottom: 3px;
    }
  }
  &--mb-0 {
    margin-bottom: 0;
  }
  &--mb-8 {
    margin-bottom: 8px;
}
  &--flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    >label {
      width: 100%;
    }
  }
  &--mt-16 {
    margin-top: 16px;
  }
  &--search {
    width: 250px;
    max-width: 100%;
    .input-icon {
      width: 18px;
      font-size: 21px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 0;
    }
  }
  &--search-bigger {
    width: 290px;    
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 0;
    }
  }
  &--search-translate{
    width: 380px;    
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 0;
    }
  }
  &--error {
    .input-with-select {
      border: 1px solid var(--ui-error-color);
    }
  }
  &--calendar {
    width: 270px;
    max-width: 100%;
    margin-bottom: 0; 
  }
  &--time {
    width: 190px;
    max-width: 100%;
    margin-bottom: 0;

  }
  &--calendar, &--time {
    @media screen and (max-width: 620px) {
      width: 100%;
      margin-bottom: 0;
    }
    .input-icon {
      width: 16px;
      svg path {
        stroke: $text-grey-light;
      }
    }
  }
  &__drop {
    display: none;
  }
  &.active {
    .input__drop {
      display: block;
    }
  }
}

.textarea {
  &__name {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 18px;
    color: #777E91;
  }
  &__item {
    width: 100%;
    min-height: 109px;
    resize: none;
    outline: none;
    border: 1px solid var(--ui-input-border-color);
    border-radius: 5px;
    padding: 10px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400; 
    @include input-placeholder {
      color: $text-grey-light;     
      font-weight: 400;        
      transition: all 0.15s ease;
    }
    &--big {
      height: 368px;
    }
    &--big-with-send {
      height: 321px;
    }
    &--type2 {
      border-color: $grey;
      border-radius: 10px;
      font-size: 14px;
    }
    &--size2 {
      min-height: 171px;
      
    }
    &--size3 {    
      min-height: 130px;
    }
  }
  &--mt-16 {
    margin-top: 16px;
  }
}

.textarea-name {
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 18px;
  color: #777E91;
} 

.input-item {
  &--search {
    height: 42px;
    border-color: #E6E8EC;
    border-radius: 4px;
    background: #fff;
    @include input-placeholder {
      color: $text-grey-light;     
    }
  }
  &--br-4 {
    border-radius: 4px;
  }
}

.input-info {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  &__currency {
    color: var(--font-color-fourth);
    font-weight: 400;
    font-size: 16px;
    &--type2 {       
      color: $text-dark;
      font-weight: 500;
    }
  }
  &__action {
    flex: 0 0 16px;
    max-width: 16px;
  }
}

.input-drop-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  svg {
    width: 12px;
  }
}

.select-with-label {
  display: flex;
  align-items: center;
  &__text {
    height: 42px;
    flex: 0 1 auto;
    align-content: center;
    padding: 0 16px;
    background: $main-color;
    color: #fff;
    border-radius: 5px 0 0 5px;
    font-size: 15px;
  }
  .select {
    flex: 1;
    min-width: 200px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &__current {
      font-size: 15px;
    }
  }
}

.input-with-select {
  display: flex;
  align-items: center;
  border: 1px solid var(--ui-input-border-color);
  border-radius: 12px;
  .select {
    flex: 0 1 auto;
    min-width: 120px;    ;
    background: #fff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    border-right: 1px solid var(--ui-input-border-color);
    background: transparent;
    &--height-42 {
      height: 40px;
    }
    &__current {
      justify-content: space-between;      
      width: auto;
      flex: 1;
      height: 100%;
      padding: 0 16px;
      font-size: 15px;
      color: $text-grey-light;
      white-space: nowrap;
    }
    &__current-arrow svg path{
      stroke: $text-grey-light;
    }
    &__drop {
      width: max-content;
      ul {
        >li {
          >a, >button {
            font-size: 15px;
          }
        }
      }
    }
  }
  .input-wrapper {
    flex: 1;
  }
  .input-item {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    border: none !important;
    background: transparent ;
    &--search {
      background: #fff;
    }
  }
  &--type2 {
    border: none;
    .select {
      border-right: 0 ;
      border-radius: 4px 0 0 4px;
      background: #fff;     
      &__current {
        color: $text-dark;
        &--unselect {
          color: $text-grey-light !important;
          .select__current-arrow svg path{
            stroke: $text-grey-light;
          }
        }
      }
      &__current-arrow svg path{
        stroke: $text-dark;
      }
    }
    .input-item {
      border-radius: 0 4px 4px 0;
      border: 1px solid var(--ui-input-border-color) !important;
    }
  }
  &--type3 {
    height: 50px;
    .select {
      background: $green;
      &__current {
        color: #fff !important;
        .select__current-arrow svg path{
          stroke: #fff;
        }
      }
    }
  }
  &--filter {
    @media screen and (max-width: 475px) {
      gap: 16px;
      flex-wrap: wrap;
      .select {
        flex: 0 0 100%;
        width: 100%;
      }
    }
  }
}

.symbol-count {  
  text-align: right;
  font-size: 12px;
  color: $grey2;
}

.select-coin {
  display: flex;
  align-items: center;
  width: 30px;   
}

.select-coin-name {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  &__extra {
    color: $grey2;
  }
}

.drop-search {
  padding: 16px 16px 0 16px;
}

.switch {
  gap: 10px;
  height: auto;
  border-radius: 8px;
  background-color: #fff;
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #777E90;
    cursor: pointer;
    &.active {
      background-color: $green;
      color: #fff;
    }
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
  &--type3 {
    width: unset;
    .switch__toggler {
      background: #b6b6b6;      
    }
    .switch__toggler:before {
      background: #fff;
    }
    input:checked ~ .switch__toggler {
      background: $green;
      &:before {
        background: #fff;
        left: 18px;
      }
    }
  }
  &--label-type {
    display: inline-flex;
    background: none;
    .switch__text+.switch__label {
      margin-left: 0;
    }
  }
  &__text {
    &+.switch__label {
      margin: 0;
    }
    &--smaller {
      font-size: 12px;
    }
  }
}

.input-badge {
  position: absolute;
  left: 16px;
  top: 50%;transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 50px;
  color: #777E90;
  font-weight: 400;    
  &--long {
    width: 95px;
  }  
}

.form-group {
  margin-top: 24px; 
  margin-bottom: 16px;
}

.input-switch {
  display: flex;
  align-items: center;
  gap: 10px;
  &__text {
    font-size: 14px;
    font-weight: 500;
    &--grey {
      color: $text-grey;
    }
    &--fw-400 {
      font-weight: 400;
    }
  }
  .switch {
    width: auto;
    height: auto;
  }
  &+.input-switch {
    margin-top: 8px;
  }
}

.input-error-note {
  position: relative;
  display: block;
  margin-top: 8px;
  padding-left: 15px;
  color: #F44336;
  font-size: 12px;
  line-height: 22px;
  &::before {
    content: '*';
    position: absolute;
    top: 0;
    left: 0;
  }
}

.copy-buttun, .copy-button {
  position: relative;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  //padding-right: 16px;
  max-width: 100%;
  white-space: nowrap;
  &__text {
    display: block;
    flex: 1;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__item {
    display: flex;
    width: 16px;
    height: 16px;
  }
}

.table--transaction {
  background: #fff;
}

.status-arrow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &--flex-start {
    justify-content: flex-start;
  }
  &__button { 
    display: inline-block;
    width: 15px;
    height: 15px;
    background-image: url('../images/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.transaction-export-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 42px;
  padding: 4px 12px 4px 36px;
  background-color: #fff;
  border: 1px solid #E6E8EC;
  border-radius: 5px;
  color: rgba(119, 126, 144, 0.5);
  font-size: 15px;
  transition: all 0.3s ease;
  &__icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    display: inline-flex;
    width: 18px;
    svg {
      width: 18px;
    }
    svg path {
      stroke: $text-grey-light;
    }    
  }
  &:hover {
    color: $text-dark;
    .transaction-export-btn__icon {
      path {
        transition: all 0.3s ease;
        stroke: $green;
      }
    }
    .hint-item {
      display: block;
    }
  }
  &__text {
    position: relative;
    
  }
  &--type2 {
    padding: 4px 12px;
  }
  &--red-text {
    color: #F44336;
    &:hover {
      color: #F44336;
    }
  }
  .hint-item {
    top: calc(100% + 22px);
    left: unset;
    right: -8px;
    max-width: 256px;
    padding: 10px 16px;
    font-size: 13px;
    text-align: left;
    line-height: 1.6;
    &:before {
      left: unset;
      right: 32px;
    }
  }
}

.transaction-filter {
  &__btn {
    display: inline-block;
    padding-right: 32px;
    color: #777E91;
    background-image: url('../images/close-btn.svg');
    background-repeat: no-repeat;
    background-position: 100% 0;
  }
}

.line-scroll {
  max-width: 170px;
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(34, 34, 34, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 10px;
  }
}

.market-robot{
  svg {
    path {
      stroke: #777e91;
    }
  }
  &:hover,
  &.active {
    svg {
      path {
        stroke: white;
      }
    }
  }
}

.count-label-select {
  .select {
    height: 40px;
    border-radius: 3px;
    border-color: transparent;
  }
}

.td--status {
  position: relative;
  cursor: pointer;
}

.td-action {
  display: flex;
  .button {
    height: 22px;
    width: auto;
    min-width: 63px;
    padding: 0 7px;
    border-radius: 11px;
    font-weight: 500;
    font-size: 12px;
  }
}

.status-drop {
  display: none;
  position: absolute;
  top: 30px;
  width: 100px;
  text-align: left;
  background: #fff;
  border: 1px solid #E6E8EC;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 10;
  &__list {
    text-transform: capitalize;
    &--center {
      text-align: center;
    }
  }
  &__item {
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: #fff;
    }
  }
}

.status-drop.active {
  display: block;
}

.dash-actions {
  display: flex;
  align-items: center;
  gap: 24px;
  &__item {
    display: inline-flex;
    width: 34px;
    svg {
      width: 100%;
      height: auto;
      max-height: 19px;
    }
    &:hover {
      svg path{
        fill: $green;
      }
    }
    &--stroke {
      &:hover {
        svg path{
          fill: transparent;
          stroke: $green;
        }
      }
    }
  }
}

.popup-input {
  display: flex;
  height: 40px;
  &--withdrawal {
    gap: 8px;
    .popup-input-item {
      border: 1px solid #E6E8EC;
      border-radius: 5px;
    }
  }
}

.input-copy-url {
  width: 40px;
  height: 40px;
  border: 1px solid #E6E8EC;
  border-radius: 5px 0 0 5px;
  svg {
    width: 15px;
    height: 15px;
  }
}

.popup-input-item {
  display: block;
  width: 100%;
  padding: 0 15px;
  outline: none;
  border: 1px solid #E6E8EC;
  border-left: none;
  border-radius: 0 5px 5px 0;
  &:disabled {
    background-color: #fff;
  }
  &--amount {
    width: 104px;
  }
}

.bold {
  font-weight: 500;
}

.dispute-details {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  &__left {
    width: 460px;
    max-width: 100%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
  &__right {
    flex: 1;
    max-width: 445px;
    @media screen and (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.dispute-details-table {
  .dispute-details-tr {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    font-size: 12px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  .dispute-details-td {
    &:first-child {
      font-weight: 500;
    }
    &:last-child {
      font-size: 14px;
      text-align: right;
    }
  }
}
.dispute-details-td { 
  a {
    color: #5856D6;
    text-decoration: underline !important;
  }  
}

.dispute-details-box {
  display: flex;
  align-items: center;
  justify-content: space-between;  
  gap: 20px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.dispute-details-btns {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 20px;
  &--type2 {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-top: 45px;
    max-width: 460px;
    .button {
      padding: 0 20px;
    }
  }
}

.dispute-details-document {
  width: 78px;
}

.dispute-details-descr {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.chats-form-box {
  .input-item {
    @include input-placeholder {
      opacity: 1;
    }
  }
}

.chats-form {
  display: flex;  
  flex-wrap: wrap;
  max-width: 1600px;
  margin: 0 -16px;
  &__col {
    width: 33.3%;
    padding: 0 16px;
    @media screen and (max-width: 1200px) {
      width: 50%;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
  &__left,
  &__right {
    max-width: 423px;
    width: 100%;
    padding: 0 16px;
    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }
}

.chat-history-box { 
  border: 1px solid #E6E8EC;
  border-radius: 5px;
  padding: 10px;  
  &__scroll {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-right: 5px;
    height: 348px;
    &--smaller {
      height: 284px;
    }    
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #777E91;
      border-radius: 3px;      
    }
  }
}

.history-message {
  display: flex;
  flex-direction: column;
  width: auto;
  margin-bottom: 10px;
  background: #f9f9f9;
  padding: 10px  10px;;
  border-radius: 5px;
  &__name {
    font-weight: 600;
  }
  &__text {
    font-size: 14px;
  }
  &--admin {
    margin-left: auto;
    max-width: 300px;
  }
  &--user {
    margin-right: auto;
    max-width: 300px;
    .history-message__name {
      color: #777E91;
    }
  }
}

.tokens {
  display: flex;
  align-items: center;
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  &__name {
    font-weight: 400;
    font-size: 14px;
    color: #011E26;
  }
}

.table-info {
  position: absolute; 
  left: 0;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  &__group {
    display: flex;
    gap: 10px;
  }
  &__type {
    font-weight: 500;
    font-size: 14px;
    &--red {
      color: #F44336;
    }
    &--green {
      color: #03BC70;
    }
  }
  &__date {
    color: #777E91;
  }
}

.copy-value {
  display: flex;
  gap: 8px;
  &__btn {
    width: 16px;
  }
  &__text {
    color: #777E91;
  }
}

.status-time {
  &__item{
    display: flex;
    align-items: center;
    span {
      font-weight: 500;
      color: #03BC70;
    }
  }
  &__icon  {
    width: 16px;
    height: 16px;
  }
}

.tokens-management-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0  40px;
  @media screen and (max-width:1300px) {
    gap: 0 20px;
  }
  @media screen and (max-width:1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width:767px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width:475px) {
    grid-template-columns: 1fr;
  }
}

.tokens-management-box {
  margin-top: 32px;
  border: 1px solid #E6E8EC;
  border-radius: 10px;
  padding: 24px;
  @media screen and (max-width:767px) {
    padding: 16px;
  }
  &+.tokens-management-box {
    margin-top: 16px;
  }
}

.tokens-management {
  margin-top: 32px;
  line-height: 1.4;
  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
  }
  &__value {
    margin: 16px 0;
    font-weight: 600;
    font-size: 28px;
  }
  &__btn {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    &:last-child {
      margin-top: 24px;
    }
  }
}

.token-info-box {
  background: #EFEFEF;
  border-radius: 15px;
  padding: 24px;
}

.token-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__label {
    color: $text-grey;
  }
  &__val {
    display: inline-flex;
    gap: 2px;
    font-weight: 700;
    span {
      color: $text-grey;
    }
  }
  &+.token-info {
    margin-top: 8px;
  }
}

.operation-form {
  max-width: 433px;
  .button:disabled {
    background: #777E91;
    opacity: 1;
  }
}

.faq-withdraw  {
  &__title {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #011E26;
  }
  &__name {
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #777E91;
  }
  &__value {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #011E26;
  }
}

.form-block {
  &--mt-24 {
    margin-top: 24px;
  }
}

.enter-value-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.enter-value {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 8px;
  }
  &__coin {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    color: #022A36;
    font-weight: 400;   
  }
  &__sum {
    display: block;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    padding-bottom: 16px;

    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
  }

  &__label {
    display: block;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
    &--padding {
      padding-bottom: 8px;
    }
    &--flex {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__icon {
    display: flex;
    width: 28px;
    margin-left: 13px;
    margin-right: 9px;
  }
  &__text-container {
    display: flex;
    align-items: center;
    gap: 19px;
    span {
      &:nth-child(2) {
        font-weight: 400;
      }
    }
  }
  &__text-fw400 {
    font-weight: 400;
  }
  &__field {
    position: relative;
    display: flex;
    align-items: stretch;
    margin-bottom: 24px;
    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
    }
    &--column {
      flex-direction: column;
      gap: 16px;
    }
    .input {
      flex: 1;
    }
    .input-item {
      &::placeholder {
        color: $grey2;
        opacity: 1;
      }
    }
  }

  &__text {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    word-wrap: break-word;
    width: 548px;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
  &__group {
    &+.enter-value__group {
      margin-top: 16px;
    }
  }
  &__select {
    position: static;
    border: 1px solid #e6e8ec;
    border-radius: 0 12px 12px 0;
    padding: 0 16px;
    width: 126px;
    border-left: 0;
    @media screen and (max-width: 575px) {
      width: 100%;
      height: 52px;
      border-left: 1px solid #e6e8ec;
      border-radius: 12px;
      margin-top: 12px;
    }
    .select__current {
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      justify-content: flex-start;
    }
    .select__arrow {
      margin-left: auto;
    }
    .select__drop {
      width: 100%;
      left: 0;
      margin-top: 8px;
      padding: 16px;
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }
      &-item {
        border-bottom: 0;
      }
      &-link {
        padding: 8px 0;
        color: $grey2;
        transition: 0.2s;
        &:hover {
          color: $green;
        }
      }
      &-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.block-label {  
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  padding-bottom: 24px;
  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
}

.withdrawal-option-group {
  margin-bottom: 16px;
  .withdrawal-option + .withdrawal-option {
    margin-top: 12px;
  }
}

.withdrawal-options {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  gap: 16px;
  padding: 16px;
  background: #F4F4F4;
  border-radius: 12px;
  @media screen and (max-width: 575px) {
    padding: 16px;
    gap: 6px;
  }
}

.withdrawal-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  &__title {
    display: block;
    font-size: 14px;
    line-height: 1.2;
    color: $grey;
    @media screen and (max-width: 575px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__desc {
    display: block;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
    word-break: break-all;
    &--bigger {
      font-size: 18px;
    }
    @media screen and (max-width: 575px) {
      font-size: 12px;      
    }
  }

  &__link {
    color: $green;
    font-weight: 500;
  }
  &__btn {
    display: flex;
    align-items: center;
    width: 12px;
    svg {
      transform: rotate(180deg); 
    }
  }
  &__extra {
    display: none;
    width: 100%;
    background: #fff;
    padding: 16px;
    border-radius: 12px;
    .withdrawal-option__title {
      margin-bottom: 8px;
    }
  }
  &.active {
    .withdrawal-option__extra {
      display: block;
    }
    .withdrawal-option__btn svg{
      transform: rotate(0);
    }
  }
}

body .rs-picker-menu {
  z-index: 15 ;
}

.inputs-two-column {
  display: grid;
  grid-template-columns: 1fr 1fr;  
  gap: 10px;
  @media screen and (min-width: 768px) {
    gap: 30px;
  }
  &--gap-10 {
    gap: 10px;
    @media screen and (min-width: 768px) {
      gap: 10px;
    }
  }
  &--type2 {
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.created-pair {
  font-weight: 600;
  span {
    color: $grey;
    font-weight: 500;
  }
}

.tr-currency-group {
  &+.tr-currency-group {
    border-top: 2px solid $grey;
  }
}

.admin-managment-buttons {
  display: flex;
  gap: 8px;
}

.hint-block {
  position: relative;
  display: inline-flex;
  max-width: 100%;   
  &:hover {
    .hint-item {
      display: block;
    }
  }
  &__text {
    max-width: 100%;    
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
}

.hint-item {
  display: none;
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  width: 306px;
  background: #009A5A;
  border-radius: 10px;
  padding: 14px 15px;
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  z-index: 2;
  &:before {
    content: '';
    position: absolute;
    left: 30px;
    top: -9px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #009A5A;
  }
  &--type2 {
    width: max-content;    
    max-width: 220px;
    background: #fff;
    border: 1px solid #E6E8EC;
    padding: 12px 0;
    color: $text-dark;
    &:before {
      display: none;
    }
  }
}

.permissions {
  position: relative;
  display: flex;
  align-items: center;
  .permissions-icon {
    &--action {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  &:hover {
    .hint-item {
      display: block;
    }
  }
}

.permission-item {
  padding: 0 16px;
  &:not(:first-child) {
    padding-top: 12px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #E6E8EC;
    padding-bottom: 12px;
  }
}

.text-status {
  font-weight: 500;
  &--success {
    color: #00DA83;
  }
  &--pending {
    color: $grey;
  }
  &--rejected {
    color: #F44336;
  }
}

.balance-network {
  margin-left:60px;
  color: $text-grey;
  @media screen and (max-width: 1199px) {
    margin-left: 0;
  }
} 

.staking-info-box {
  display: grid;
  grid-template-columns: repeat(2, minmax(1px, 1fr));   
  @media screen and (max-width:991px) {
    grid-template-columns: minmax(1px, 1fr);
    gap: 24px;
  }
  &--align-end {
    align-items: flex-end;
    .staking-info {
      align-self: stretch;
      align-content: flex-end;
    } 
  }
}

.staking-info {
  position: relative;
  .block-subtitle {
    font-weight: 500;
  }
  &:nth-child(2n + 1) {
    padding-right: 24px;
    @media screen and (max-width:991px) {
      padding: 0;
    }
  }
  &:nth-child(2n) {
    padding-left: 24px;
    @media screen and (max-width:991px) {
      padding: 0;
    }
    &:after {
      content:'';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background: #E6E8EC;
      @media screen and (max-width:991px) {
        display: none;
      }
    }
  }
}

.staking-stat{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  &__name {
    font-size: 14px;
    color: $grey;
  }
  &__value {
    display: flex;
    align-items: baseline;
    gap: 2px;
    font-size: 20px;
    font-weight: 500;
    span {
      position: relative;
      top: -1px;
      color: $grey;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &+.staking-stat {
    margin-top: 20px;
  }
  @media screen and (max-width:991px) {
    margin-top: 10px;
  }
}

.switcher {
  display: flex;
  border: 1px solid var(--ui-input-border-color);
  padding: 3px;
  border-radius: 5px;
  &__item {
    display: flex;    
    align-items: center;justify-content: center;
    border-radius: 4px;
    flex: 1;
    height: 39px;
    color: $grey;
    font-weight: 500;
    font-size: 16px;
    &.active {
      background: $main-color;
      color: #fff;
    }
  }
}

.translation-bar {
  position: relative;
  width: 280px;
  flex: 0 0 280px;
  background: #fff;
  min-height: 100vh;
  border-left: 1px solid #EDEDED;
  padding-bottom: 24px;
  @media screen and (max-width:991px) {
    width: 100%;
    flex: 0 0 100%;
  }
}

.translation-bar-header {
  position: sticky;
  top: 0;
  margin-bottom: 24px;
  padding: 24px 16px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  z-index: 2;
}

.translation-bar-group {
  padding: 0 16px;
  &__name {
    margin-bottom: 10px;
    font-size: 12px;
    color: $grey;
    opacity: 0.5;
  }
  &+.translation-bar-group {
    margin-top: 12px;
  }
}

.translation-bar-list {
  &.active {      
    .bar-list-arrow svg {
      transform: rotate(180deg);
    }
  }
  >li {     
    >a, >button {
       
    }
    a, button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 8px 0;
      color: var(--font-color);
      font-size: 14px;
      font-weight: 500;
    } 
    .translation-bar-list {
      li {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.translation-bar-sublist {  
  li {
    width: calc(100% + 32px);
    margin-left: -16px;
    a, button {
      padding: 8px 16px 8px 40px;
    }
    &.active {
      background: #F6F6F6;
    }
    .translation-bar-sublist {
      padding-left: 20px !important;
      li {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.bar-list-arrow {
  display: flex;
  width: 12px;
}

.translate-group {
  &.active {
    .translate-content {
      display: block;
    }
    .translate-header__btn svg {
      transform: rotate(180deg);
    }
  }
}

.translate-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 16px;
  background: #F6F6F6;
  border-radius: 8px;
  &__title {
    font-size: 12px;
    font-weight: 500;
  }
  &__btn {
    padding: 4px;
    svg {
      width: 12px;
    }
  }
}

.translate-content {
  display: none;
  padding: 16px 0;
}

.translate-item {
  display: grid;
  gap: 10px;
  min-height: 40px;
  grid-template-columns: 250px 1fr 110px;
  align-items: center;
  padding: 0 16px;
  &__name {
    padding-right: 16px;
    color: $grey;
    font-size: 14px;
    word-break: break-word;
  }
  &__value-text {
    font-size: 14px;
  }
  &__value {
    
    .input-item {
      border-radius: 8px;
      padding: 0 12px;
      font-size: 14px;
      @include input-placeholder {
        font-size: 14px;
      }
    }
  }
  &__action {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
    button {
      width: 22px;
      padding: 4px;
      svg {
        width: 100%;
      }
    }
  }
  &+.translate-item {
    margin-top: 4px;
  }
}

.input-name {
  margin-bottom: 8px;
  font-size: 12px;
  color: $grey;
  &--type2 {
    font-size: 14px;
    color: var(--font-color);
    font-weight: 500;
  }
}

.table-text-grey {
  color: #CCD2D4;
  &--second {
    color: $grey;
  }
}

.red-text {
  color: #F44336;
}

.green-text {
  color: #03BC70;
}

.table-enter-coin {
  display: flex;
  gap: 12px;
}

.input-and-btn {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  max-width: 400px;
  &--gap-10 {
    gap: 10px;
  }
  &--flex {
    display: flex;
    >div {
      flex: 1;
    }
  }
}


/*----cash-desk---------*/

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__title {
    h2 {      
      font-weight: 700;
      font-size: 18px;      
    }
    p {
      font-size: 14px;
    }
  }
}

.stats {
  &--cashdesk {
    margin-top: 16px;
    padding: 20px;
    background: #F8F8F8;
    border-radius: 8px;
    .stats__info {
      width: 574px;      
      max-width: 100%;
      @media screen and (min-width: 1199px) {
        min-width: 530px;
      }
    }
  }
  &__header {
    &--type2 {      
      justify-content: space-between;      
    }
  }
  &__header-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  &__list {     
  }
}

.stats-item-box {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(1px, 1fr) 16px;
  gap: 8px;
}

.cashdesk-qr {
  margin-top: 20px;
  width: 250px;
  &__title {
    font-size: 14px;
    font-weight: 500;
  }
  &__item {
    margin-top: 10px;
    width: 150px;
  }
  &__action {
    display: flex;
    margin-top: 10px;
  }
}

.cashdesk-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  .button {
    font-weight: 400;
  }
}

.action-btn {
  display: flex;
  justify-content: center;
  width: 16px;
  min-width: 14px;
}

.content-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  min-height: 44px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &--content-right {
    @media screen and (min-width: 768px) {
      justify-content: flex-end;
    }
  }
  .button {
    height: 44px;
    border-radius: 22px;
  }
}

.create-operator-form {
  display: grid;
  grid-template-columns: minmax(1px, 1fr);
  margin-top: 16px;
  background: #fff;  
  border-radius: 10px;
  @media screen and (min-width: 768px) {
    grid-template-columns: minmax(1px, 1fr) 337px;
  }
  .forms-block {
    width: 100%;
    padding: 0;
    &:not(:first-child) {
      border-top: 2px solid #E6E8EC;
      @media screen and (min-width: 768px) {
        border-left: 2px solid #E6E8EC;
        border-top: none;
      }      
    }
  }
  .form {
    width: 100%;
    margin: 0;
    padding: 16px;
    border: none;
    border-radius: 10px;
    @media screen and (min-width: 768px) {
      padding: 24px 38px;
    }
  }
  .inputs-two-column--type2 {
    gap: 0;
    @media screen and (min-width: 768px) {
      gap: 30px;
    }
  }
}

.operation-balance-list {
  margin-bottom: 16px;
}

.operation-balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  &+.operation-balance {
    margin-top: 10px;
  }
}

/*----end-cash-desk-------*/

@media screen and (max-width: 1439px) {
  .td {
    &--word-br {
      p {
        max-width: 145px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }
  }
}

@media screen and (max-width: 1299px) {
  .user-info {
    row-gap: 15px;
  }
}

@media screen and (max-width: 1259px) {
  .user-settings {
    justify-content: flex-start;
    &__item {
      border-radius: 8px;
      &:not(:last-child) {
        margin-right: 14px;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .table-info {
    top: 10px;
    flex-wrap: wrap;
  }
  .td {
    &--right {
      text-align: left;
      display: block;
      .td-action {
        justify-content: flex-start;
      }
    }

    &--center {
      text-align: left;
      .switch {
        margin: 0;
      }
    }

    &--status {
      justify-content: flex-start;
    }
    &--documents {
      justify-content: flex-start;
    }
  }
  .table-buttons {
    justify-content: flex-start;
  }
  .td {
    &--status {
      display: block;
    }
  }

  .table-input {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 620px) {
  .count-label {
    &--transaction {
      justify-content: flex-start;
      row-gap: 10px;
    }
  }
}

@media screen and (max-width: 435px) {
  .tfa-block__qr-code {
    font-size: 12px;
  }
  .table--P2P-trades .table-body .tr {
    padding-top: 50px;
  }
}

