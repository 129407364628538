.select {
	&--error {
		border: 2px solid var(--ui-error-color);
	}
}

.no-options-text {
	margin-left: 16px;
}
.select__current {
	justify-content: flex-start;
}
.select-coin_playceholder {
	width: auto;
	margin-right: auto;
}
